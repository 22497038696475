import React from "react";
import { useTranslation } from "react-i18next";
import "assets/styles/payout-table.scss";

const PayoutTable = ({ payTableData }) => {
  const { t } = useTranslation();

  let table1 = payTableData;
  let table2 = [];

  if (table1 && table1.length > 4) {
    table1 = payTableData.slice(0, 4);
    table2 = payTableData.slice(4, 10);
  }

  return (
    <div className="payout-table">
      <div className="header">
        {t("common.payout")}
        <div className="border" />
      </div>
      <div className="data-groups">
        {[table1, table2]
          .filter((table) => table.length)
          .map((table) => (
            <div className="table">
              <div className="group">
                <div className="group-header">{t("common.hits")}</div>
                <div className="group-header">{t("common.pay")}</div>
              </div>
              <div className="data-list">
                {table.map((data) => (
                  <div className="item">
                    <div>{data[0]}</div>
                    <div>{data[1]}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        {!table1.length && !table2.length ? (
          <div className="table">
            <div className="group">
              <div className="group-header">{t("common.hits")}</div>
              <div className="group-header">{t("common.pay")}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PayoutTable;
