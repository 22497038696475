import React from "react";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import GlobalState from "context/GlobalState";

import i18n from "utils/i18next";
import store from "./store";
import AppRoutes from "./routes/AppRoutes";
import theme from "./theme";
import "./App.scss";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <GlobalState>
          <Provider store={store}>
            <Router>
              <StylesProvider injectFirst>
                <AppRoutes />
              </StylesProvider>
            </Router>
          </Provider>
        </GlobalState>
      </I18nextProvider>
    </ThemeProvider>
  );
}

export default App;
