export const transformAudioMappings = (mappings) => {
  const keys = Object.keys(mappings);
  const results = {};

  keys.forEach((key) => {
    const offset = mappings[key][0]
    const duration = mappings[key][1]

    results[key] = [offset * 1000, (duration - offset) * 1000];
  })

  return results;
}