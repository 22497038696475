import React from 'react';
import { Route, Switch, Redirect as ReactRouterRedirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import AppRoute from './AppRoute';
import Game from 'pages/Game';

/* catch-all */
const NotFoundRoute = ({ fallback }) => <ReactRouterRedirect to={fallback} />;

NotFoundRoute.defaultProps = {
  fallback: '/',
};

NotFoundRoute.propTypes = {
  fallback: PropTypes.string,
};

const AppRoutes = () => {
  return (
    <Switch>
      <AppRoute path="/" exact component={Game} />
      <Route component={NotFoundRoute} />
    </Switch>
  );
};

export default AppRoutes;
