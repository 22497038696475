import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DropUpImage from "assets/images/dropup.png";
import "assets/styles/drop-up-button.scss";

const DropUpButton = ({
  disabled,
  onClick,
  label,
  options,
  value,
  className,
}) => {
  const [showDropUp, toggleDropUp] = useState(false);

  const getOptionClass = (option) => {
    let clasName = "option";

    if (option === value) {
      clasName = `${clasName} active`;
    }

    return clasName;
  };

  const handleValueChanged = (value) => {
    onClick(value);
    toggleDropUp(false);
  };

  return (
    <div className={`drop-up-button-wrapper ${className}`}>
      {showDropUp ? (
        <>
          <div onClick={() => toggleDropUp(false)} className="overlay" />
          <div className="drop-up">
            {options.map((option, index) => (
              <div
                key={index}
                onClick={() => handleValueChanged(option)}
                className={getOptionClass(option)}
              >
                {option}
              </div>
            ))}
          </div>
        </>
      ) : null}
      <Button
        disabled={disabled}
        onClick={() => toggleDropUp(!showDropUp)}
        color="secondary"
        variant="contained"
        className="drop-up-button"
      >
        <img className="drop-up-img" alt="DropUpImg" src={DropUpImage} />
        {label}
      </Button>
    </div>
  );
};

DropUpButton.defaultProps = {
  disabled: false,
  options: [],
  onClick: () => {},
};

export default DropUpButton;
