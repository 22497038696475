export const numberList = (max) => {
    const numbers = [...Array(max)];

    return numbers.map((item, index) => index + 1);
};

export const formatMoney = (money) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    let formatted = formatter.format(money);

    if (money > 9999) {
        return formatted.substr(0, formatted.indexOf('.'));
    }

    return formatted;
}
export const parseNumber = (response) => {
    return {
        numbers: stringListToArray(response.NUMBERS),
        hits: stringListToArray(response.HITS1),
        prize: response.PRIZE,
    }
};

export const parseNumbers = (numbers) => {
    let numberRounds = [];

    numbers.forEach((response) => {
        numberRounds.push(
            {
                numbers: stringListToArray(response.NUMBERS),
                hits: stringListToArray(response.HITS1),
                prize: response.PRIZE,
            }
        );
    });

    return numberRounds;
};

export const stringListToArray = (str) => {
    return str.split(',')
        .filter(number => number.trim() !== '')
        .map((number) => parseInt(number));
};

export const getRandomNumber = (max) => {
    return Math.floor(Math.random() * max) + 1;
};

export const getNumberOfRandomNumbers = (length, maxNumber) => {
    let numbers = [];
  
    while (numbers.length < length) {
      const randNumber = getRandomNumber(maxNumber);
      const exist = numbers.includes(randNumber);
      if (!exist) {
        numbers = [...numbers, randNumber];
      }
    }
  
    return numbers;
  };

export const calculatePayTable = (numbers, config) => {
    const max = numbers.length;
    let result = [];
    let count = max;

    for (let i = max; i >= 0; i--) {
        const index = `PAYOUT1[${i},${max}]`;

        if (config[index]) {
            result.push([count--, config[index]]);
        }
    }

    return result;
}

export const parseBetOptions = (raw) => {
    const numbers = raw.split(',').filter(num => num !== '');

    return numbers.map((number) => number / 100)
}