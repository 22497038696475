import { getAcValue } from "utils/api-communication";

export const replayGame = async (api, game, next, callbacks) => {
  const state = await api.getState({
    ...game,
    NEXT: next,
  });

  let acValue = getAcValue(state);
  let canPlay = true;

  if (acValue) {
    acValue = acValue.toLowerCase();
  }

  if (next === 1 && acValue !== "start") {
    canPlay = false;
  }

  switch (acValue) {
    case "start":
      await api.start({
        GAMEID: game.GAMEID,
        USERNUM: game.USERNUM,
        USERID: game.USERID,
      });
      replayGame(api, game, next + 1, callbacks);
      break;
    case "buytickets":
      const ticket = await api.buyTickets({
        GAMEID: game.GAMEID,
        USERNUM: game.USERNUM,
        USERID: game.USERID,
        DRAWS: state.DRAWS,
        NUMBERS: state.NUMBERS,
        DENOMINATION: state.DENOMINATION,
        NEXT: next,
      });
      callbacks.onReplayTicketBought(
        {
          ...state,
          ...ticket,
        },
        next + 1
      );
      break;
    case "win":
    case "lose":
      replayGame(api, game, next + 1, callbacks);
      break;
    case "finish":
      await api.finish({
        GAMEID: game.GAMEID,
        USERNUM: game.USERNUM,
        USERID: game.USERID,
      });
      await api.getState({
        ...game,
        NEXT: next + 1,
      });
      callbacks.onGameReplayFinished();
      break;
    default:
      if (canPlay) {
        const roundResult = await api.play({
          GAMEID: game.GAMEID,
          USERNUM: game.USERNUM,
          USERID: game.USERID,
        });
        callbacks.onReplayRound(roundResult, next + 1);
      } else {
        callbacks.onGameReplayFinished();
      }
      break;
  }
};

export const prepareRounds = (count) => {
  const round = {
    numbers: [],
    hits: [],
    prize: 0,
    dummy: true,
  };
  let rounds = [];

  for (let i = 0; i < count; i++) {
    rounds.push(round);
  }

  return rounds;
};
