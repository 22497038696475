export const SET_ERROR = 'SET_ERROR'

export const setError = (dispatch) => {
  return (error, canClose = false) => {
    dispatch({
      type: SET_ERROR,
      data: {
        error,
        canClose
      }
    })
  }
}
