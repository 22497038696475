import React from "react";
import SimpleTable from "components/SimpleTable";
import PayoutTable from "components/PayoutTable";
import "assets/styles/winnings-status.scss";
import { useTranslation } from "react-i18next";

const WinningsStatus = ({ roundsData, payTableData }) => {
  const { t } = useTranslation();
  let wrapperClass = "";

  if (payTableData.length > 4) {
    wrapperClass = "thirty";
  }

  return (
    <div className={`winnings-status ${wrapperClass}`}>
      <PayoutTable payTableData={payTableData} />
      <SimpleTable
        separator
        title={t("common.result")}
        data={roundsData.filter((r) => !r.dummy)}
        heading1={t("common.round")}
        heading2={t("common.hits")}
      />
    </div>
  );
};

WinningsStatus.defaultProps = {
  roundsData: [],
};

export default WinningsStatus;
