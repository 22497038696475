export const SpeedTable = {
  1: {
    HOPPER_SPEED: 1000
  },
  2: {
    HOPPER_SPEED: 900
  },
  3: {
    HOPPER_SPEED: 700
  },
  4: {
    HOPPER_SPEED: 500
  },
  5: {
    HOPPER_SPEED: 200
  },
};