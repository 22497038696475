export const MIN_SIMPLE_TABLE_DATA = 0;

export const fillTableData = (source) => {
  const data = [...source];
  const lacking =  MIN_SIMPLE_TABLE_DATA - data.length;

  for(let i = 0; i < lacking; i++) {
    data.push(['', '']);
  }

  return data;
};

export const formatNumberRounds = (list) => {
  let result = [];

  if(!list) {
    return result;
  }

  list
  .filter(round => !round.dummy)
  .forEach((item, index) => {
    result.push([index + 1, item.hits.length]);
  });

  return result;
};