import { useTranslation } from 'react-i18next';
import 'assets/styles/preloader.scss';

const AppPreLoader = ({ isDoneLoading }) => {
    const { t } = useTranslation();
    return (
        <div
            className={`preloader ${isDoneLoading ? 'preloader-fadeout' : ''}`}
        >
            <p className="preloader-text">{t('common.loading')}</p>
        </div>
    );
};

export default AppPreLoader;
