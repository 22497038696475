import React from "react";
import GrayBall from "assets/images/gray-ball.js";

const Ball = ({ position, elementClass, number }) => {
  const style = {
    ...position,
    width: 33,
    height: 33,
  };

  return (
    <div style={style} className={`ball-wrapper ${elementClass}`}>
      <img
        src={`data:image/png;base64, ${GrayBall}`}
        alt="GrayBall"
        className="ball"
      />
      <div className="number">{number}</div>
    </div>
  );
};

Ball.defaultProps = {
  position: {},
  number: "",
};

export default Ball;
