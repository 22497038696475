import React from "react";
import BankIcon from "assets/images/bank-icon.png";
import BetIcon from "assets/images/bet-icon.png";
import WinIcon from "assets/images/win-icon.png";
import GameMenu from "components/GameMenu";
import { formatMoney } from "utils/numbers";
import "assets/styles/app-header.scss";
import { useTranslation } from "react-i18next";

const AppHeader = ({ bank, gameId, bet, win, soundOn, toggleSound }) => {
  const { t } = useTranslation();

  return (
    <div className="app-header">
      <div className="items">
        <div className="item">
          <div className="inputs">
            <img className="icon" alt="BankIcon" src={BankIcon} />
            <div className="value-wrapper">
              <span className="value">{formatMoney(bank)}</span>
              <div className="label">{t("common.bank")}</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inputs">
            <img className="icon" alt="BetIcon" src={BetIcon} />
            <div className="value-wrapper">
              <span className="value">{formatMoney(bet)}</span>
              <div className="label">{t("common.bet")}</div>
            </div>
          </div>
        </div>
        <div className="item">
          <div className="inputs">
            <img className="icon" alt="WinIcon" src={WinIcon} />
            <div className="value-wrapper">
              <span className="value">{formatMoney(win)}</span>
              <div className="label">{t("common.win")}</div>
            </div>
          </div>
        </div>
        <div className="game-id">
          <p>{t("common.gameId")}:</p>
          <p>{gameId}</p>
        </div>
      </div>
      <GameMenu soundOn={soundOn} toggleSound={toggleSound} gameId={gameId} />
    </div>
  );
};

AppHeader.defaultProps = {
  bank: "$0.00",
  win: 0,
};

export default AppHeader;
