import axios from 'axios';
import qs from 'query-string';
import { parseResponse } from 'utils/api-communication';

const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
};

export const startGame = async (urlParams) => {
    return await axios.post(
        `./gameserver/GameLaunchServlet?LANGUAGE=en&USERID=${urlParams.USERID}&SESSIONID=${urlParams.SESSIONID}&GAMEID=KE1&LAUNCHER=PARLAY&MOBILE=false`,
        qs.stringify({
            LANGUAGE: 'en',
            USERID: urlParams.USERID,
            SESSIONID: urlParams.SESSIONID,
            GAMEID: 'KE1',
            LAUNCHER: 'PARLAY',
            MOBILE: false,
        }),
        {
            headers,
        }
    );
};

const basic = async ({ GAMEID, USERNUM, USERID, TRANTYPE, CONFIGID }) => {
    const params = {
        GAMETYPE: 'KE',
        CONFIGID,
        TRANTYPE,
        GAMEID,
        USERNUM,
        USERID,
    };

    return sendCasinoServlet(params);
};

const sendCasinoServlet = async (params) => {
    const response = await axios.get(
        `./gameserver/CasinoServlet?${qs.stringify(params)}`,
        {
            headers,
        }
    );

    const parsed = parseResponse(response.data);

    if (parsed.ERROR) {
        throw new Error({ message: parsed.ERROR });
    }

    return parsed;
};

export const gameLogin = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'GAMELOGIN',
    });
};

export const getState = async (
    { GAMEID, USERNUM, USERID, CONFIGID, NEXT },
    others
) => {
    const params = {
        VERSION: 'FLASH',
        GAMETYPE: 'KE',
        TRANTYPE: 'GETSTATE',
        CONFIGID,
        GAMEID,
        USERNUM,
        USERID,
        NEXT,
        ...others,
    };

    return sendCasinoServlet(params);
};

export const getConfig = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'GETCONFIG',
    });
};

export const start = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'START',
    });
};

export const getGameCounter = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'GETGAMECOUNTER',
    });
};

export const buyTickets = async (params) => {
    const payload = {
        GAMETYPE: 'KE',
        TRANTYPE: 'BUYTICKETS',
        NUMBEROFCARDS: 1,
        ...params,
    };

    return sendCasinoServlet(payload);
};

export const play = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'PLAY',
    });
};

export const finish = async (params) => {
    return basic({
        ...params,
        TRANTYPE: 'FINISH',
    });
};
