import React from "react";
import { Button } from "@material-ui/core";
import BetAmountInput from "components/BetAmountInput";
import DropUpButton from "components/DropUpButton";
import "assets/styles/game-actions.scss";
import { useTranslation } from "react-i18next";

const GameActions = (props) => {
  const { t } = useTranslation();
  const {
    bet,
    betOptions,
    setBet,
    speed,
    setSpeed,
    handleRandomPick,
    quickPick,
    handleClear,
    playGame,
    isGamePlaying,
  } = props;

  return (
    <div className="game-actions">
      <div className="highlight-border" />
      <div className="top">
        <BetAmountInput
          isGamePlaying={isGamePlaying}
          betOptions={betOptions}
          onBetChanged={(value) => setBet(value)}
          value={bet}
        />
        <Button
          disabled={isGamePlaying}
          onClick={handleClear}
          color="secondary"
          variant="contained"
          className="purple-button"
        >
          {t("common.clear")}
        </Button>
        <DropUpButton
          options={[1, 2, 3, 4, 5]}
          value={speed}
          onClick={(value) => setSpeed(value)}
          label={
            <>
              <span className="label">{t("common.speed")}</span>
              <span className="number">{speed}</span>
            </>
          }
        />
        <DropUpButton
          className="quick-pick"
          options={[2, 3, 4, 5, 6, 7, 8, 9, 10]}
          value={quickPick}
          onClick={(value) => handleRandomPick(value)}
          disabled={isGamePlaying}
          label={
            <>
              <span className="label">{t("common.quickPick")}</span>
              <span className="number">{quickPick}</span>
            </>
          }
        />
      </div>
      <div className="bottom">
        <Button
          disabled={isGamePlaying}
          onClick={() => playGame(1)}
          color="secondary"
          variant="contained"
        >
          <span className="label">{t("common.play")}</span>
          <span className="number">1</span>
        </Button>
        <Button
          disabled={isGamePlaying}
          onClick={() => playGame(3)}
          color="secondary"
          variant="contained"
        >
          <span className="label">{t("common.play")}</span>
          <span className="number">3</span>
        </Button>
        <Button
          disabled={isGamePlaying}
          onClick={() => playGame(5)}
          color="secondary"
          variant="contained"
        >
          <span className="label">{t("common.play")}</span>
          <span className="number">5</span>
        </Button>
      </div>
      <div className="highlight-border bottom" />
    </div>
  );
};

export default GameActions;
