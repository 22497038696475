import React from "react";
import AudioFile from "audio/compiled/output.mp3";
import AudioSpriteMap from "audio/map";
import { transformAudioMappings } from "utils/audio";
import { Howl } from "howler";
import "assets/styles/button-wrapper.scss";

const ButtonWrapper = (props) => {
  const sound = new Howl({
    src: [AudioFile],
    sprite: transformAudioMappings(AudioSpriteMap),
  });

  const handleClick = () => {
    sound.play("Keno_Option_Normal_button_select");
  };

  return (
    <div className="button-wrapper-parent" onClick={handleClick}>
      {props.children}
    </div>
  );
};

export default ButtonWrapper;
