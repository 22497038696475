import * as game from "./game";

const useApi = (context, settings) => {
  const { ...objects } = game;
  let CONFIGID = settings.CONFIGID;

  if (CONFIGID === undefined) {
    CONFIGID = 4;
  }

  const catchErrors = (method) => async (params) => {
    context.setError("");
    let result = {};

    try {
      result = await method.call(null, {
        ...params,
        CONFIGID,
        USERNUM: settings.USERNUM,
        USERID: settings.USERID,
      });
    } catch (error) {
      context.setError(error.message);
    }

    return result;
  };

  let methods = {};

  Object.keys(objects).forEach((key) => {
    methods[key] = catchErrors(objects[key]);
  });

  return methods;
};

export default useApi;
