import React, { useContext, useEffect, useState } from 'react';
import { Howl, Howler } from 'howler';
import 'assets/styles/game.scss';
import BoardNumbers from 'components/BoardNumbers';
import {
    parseNumber,
    getNumberOfRandomNumbers,
    calculatePayTable,
    parseBetOptions,
    stringListToArray,
} from 'utils/numbers';
import useApi from 'api';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { formatNumberRounds } from 'utils/array';
import { replayGame, prepareRounds } from './functions';
import AppHeader from 'components/AppHeader';
import AppPreLoader from 'components/AppPreLoader';
import WinningsStatus from 'components/WinningsStatus';
import GameActions from 'components/GameActions';
import BallHopper from 'components/BallHopper';
import ErrorModal from 'components/ErrorModal';
import KenoLogo from 'assets/images/keno-logo.png';
import { SpeedTable } from 'constants/speed';
import Context from 'context';
import AudioFile from 'audio/compiled/output.mp3';
import BackgroundSound from 'audio/compiled/Keno_BG_Music.mp3';
import AudioSpriteMap from 'audio/map';
import { transformAudioMappings } from 'utils/audio';
import { calculateAnimationDelay } from 'utils/animation';
import { useTranslation } from 'react-i18next';

const Game = () => {
    const { t } = useTranslation();
    const sound = new Howl({
        src: [AudioFile],
        sprite: transformAudioMappings(AudioSpriteMap),
    });
    const location = useLocation();
    const context = useContext(Context);
    const urlParams = queryString.parse(location.search);
    const USERNUM = urlParams.SESSIONID;
    const USERID = urlParams.USERID;
    const roundStorageKey = `${USERID}-keno`;
    const api = useApi(context, {
        CONFIGID: urlParams.CONFIGID,
        USERNUM,
        USERID,
    });
    const [gameNumbers, setGameNumbers] = useState([]);
    const [speed, setSpeed] = useState(3);
    const [animatedNumberIndex, setAnimatedNumberIndex] = useState(-1);
    const [quickPick, setQuickPick] = useState(2);
    const [gameState, setGameState] = useState({
        soundOn: true,
        isGamePlaying: false,
        isReplay: false,
        isFetchingRounds: false,
        storedLastRound: null,
        currentRound: -1,
        currentRoundToRender: -1,
        ACCOUNTVALUE: 0,
        DRAWS: 0,
        numberRounds: [],
        ignoreRound: false,
    });
    const [bet, setBet] = useState(0);
    const [currentBet, setCurrentBet] = useState(0);
    const [isLoading, setIsLoading] = useState(true);

    const { numberRounds } = gameState;
    const lastRound = localStorage.getItem(roundStorageKey);

    const resetNumberRounds = () => {
        setGameState((currentState) => ({
            ...currentState,
            numberRounds: [],
        }));
    };

    const handleCloseErrorModal = () => {
        context.setError(null);
    };

    const handleSetSpeed = (newSpeed) => {
        sound.play('Keno_Option_Normal_button_select');
        setSpeed(newSpeed);
    };

    const handleSetBet = (newBet) => {
        if (newBet === bet) {
            return;
        }

        if (newBet > bet) {
            sound.play('Keno_bet_plus');
        } else {
            sound.play('Keno_bet_minus');
        }

        setBet(newBet);
    };

    const handleNumberSelect = (number) => {
        if (gameState.isGamePlaying) {
            return;
        }

        if (gameNumbers.indexOf(number) !== -1) {
            sound.play('Keno_number_unselect');
            setGameNumbers(gameNumbers.filter((gn) => gn !== number));
        } else if (gameNumbers.length < 10) {
            sound.play('Keno_number_select');
            setGameNumbers([...gameNumbers, number]);
        }
        resetNumberRounds();
    };

    const handleRandomPick = (picks) => {
        const numbers = getNumberOfRandomNumbers(picks, 80);

        resetNumberRounds();
        setGameNumbers(numbers);
        setQuickPick(picks);
        sound.play('Keno_quickpick_button_select');
    };

    const handleCelebrationSound = (round) => {
        const actualPrize = numberRounds
            .map((round) => round.prize)
            .reduce((r1, r2) => r1 + r2);

        if (actualPrize > 0) {
            sound.play('Keno_Win_Celebration_big');
        }
    };

    const handleRoundChanged = (currentRound) => {
        setAnimatedNumberIndex(-1);
        if (!gameState.isReplay) {
            if (currentRound < gameState.numberRounds.length) {
                onPlayRound(currentRound, gameState.DRAWS);
            }

            if (currentRound >= gameState.numberRounds.length) {
                handleCelebrationSound(currentRound);
                onGameFinished(currentRound);
            }
        } else if (currentRound < gameState.numberRounds.length) {
            replayGame(api, gameState, gameState.nextReplay, replayCallbacks);
        } else if (currentRound >= gameState.numberRounds.length) {
            onGameFinished(currentRound);
        }
    };

    const handleClear = () => {
        setGameNumbers([]);
        resetGame();
        sound.play('Keno_number_unselect');
    };

    const resetGame = () => {
        setGameState({
            ...gameState,
            currentRound: -1,
            currentRoundToRender: -1,
            numberRounds: [],
        });
    };

    const playGame = async (DRAWS) => {
        localStorage.removeItem(roundStorageKey);

        let audio = 'Keno_Play_5_button';

        if (DRAWS === 1) {
            audio = 'Keno_Play_1_button';
        } else if (DRAWS === 3) {
            audio = 'Keno_Play_3_button';
        }

        sound.play(audio);

        if (gameNumbers.length < 2) {
            return context.setError(t('error.pick2Numbers'), true);
        }

        setCurrentBet(bet);
        setGameState({
            ...gameState,
            currentRound: -1,
            currentRoundToRender: -1,
            animatedNumberIndex: -1,
            isGamePlaying: true,
            isReplay: false,
            DRAWS,
            numberRounds: [],
        });

        await playGameRounds(DRAWS);
    };

    const playGameRounds = async (DRAWS) => {
        await api.start({
            GAMEID: gameState.GAMEID,
        });
        await api.getGameCounter({
            GAMEID: gameState.GAMEID,
        });
        await api.buyTickets({
            GAMEID: gameState.GAMEID,
            DRAWS: DRAWS,
            NUMBERS: gameNumbers.join(','),
            DENOMINATION: bet * 100,
        });

        let rounds = prepareRounds(DRAWS);

        setGameState((currentState) => ({
            ...currentState,
            numberRounds: rounds,
            storedLastRound: null,
            ignoreRound: false,
        }));
    };

    const onPlayRound = async (currentRound) => {
        let round;
        if (
            gameState.numberRounds[currentRound] &&
            gameState.numberRounds[currentRound].dummy
        ) {
            const playCall = await api.play({
                GAMEID: gameState.GAMEID,
            });

            round = parseNumber(playCall);
        }

        setGameState((currentState) => {
            let rounds = [...currentState.numberRounds];

            if (round) {
                rounds.splice(currentRound, 1, round);
            }

            return {
                ...currentState,
                numberRounds: rounds,
                isFetchingRounds:
                    currentState.storedLastRound !== null &&
                    currentRound < currentState.storedLastRound,
                isGamePlaying: currentRound < currentState.numberRounds.length,
                currentRound,
                currentRoundToRender: currentRound - 1,
            };
        });
    };

    const onGameFinished = async (currentRound) => {
        const finishState = await api.finish({
            GAMEID: gameState.GAMEID,
        });

        await api.getState({
            GAMEID: gameState.GAMEID,
        });

        setGameState((currentState) => ({
            ...currentState,
            ...finishState,
            isGamePlaying: currentRound < currentState.numberRounds.length,
            isFetchingRounds: false,
            currentRound,
            currentRoundToRender: currentRound - 1,
        }));
    };

    const onReplayTicketBought = (replayTicket, nextReplay) => {
        let rounds = prepareRounds(replayTicket.DRAWS);

        setGameNumbers(stringListToArray(replayTicket.NUMBERS));
        setGameState((state) => ({
            ...state,
            ...replayTicket,
            numberRounds: rounds,
            nextReplay,
        }));
    };

    const onReplayRound = (playResult, next) => {
        if (Object.keys(playResult).length) {
            setGameState((state) => {
                const round = parseNumber(playResult);
                let currentRound = state.currentRound + 1;
                let rounds = [...state.numberRounds];

                let updated = {
                    ...state,
                    nextReplay: next,
                    isFetchingRounds: currentRound < state.storedLastRound,
                    currentRound,
                    currentRoundToRender: currentRound - 1,
                    ignoreRound: false,
                };

                if (
                    currentRound === state.storedLastRound - 1 &&
                    currentRound < state.DRAWS
                ) {
                    setAnimatedNumberIndex(20);

                    if (round) {
                        rounds.splice(currentRound, 1, round);
                    }

                    return {
                        ...updated,
                        currentRound,
                        numberRounds: rounds,
                        isFetchingRounds: false,
                        ignoreRound: true,
                        currentRoundToRender: currentRound,
                        storedLastRound: null,
                    };
                }

                if (round) {
                    rounds.splice(currentRound, 1, round);
                }

                return {
                    ...updated,
                    numberRounds: rounds,
                };
            });
        }
    };

    const onGameReplayFinished = () => {
        setGameState((state) => ({
            ...state,
            isFetchingRounds: false,
            isGamePlaying: false,
        }));
        setIsLoading(false);
    };

    const replayCallbacks = {
        onReplayRound,
        onReplayTicketBought,
        onGameReplayFinished,
    };

    const initializeGame = async () => {
        const game = await api.gameLogin();
        const config = await api.getConfig({
            GAMEID: game.GAMEID,
        });

        if (config.DENOMINATIONS) {
            const bets = parseBetOptions(config.DENOMINATIONS);

            if (config.COINVALUE && config.COINVALUE > 0) {
                setBet(config.COINVALUE / 100);
            } else if (bets && bets.length) {
                setBet(bets[0]);
            }
        }

        const newGameState = {
            ...gameState,
            ...game,
            ...config,
            isReplay: true,
            isFetchingRounds: lastRound !== null,
            storedLastRound:
                lastRound !== null ? parseInt(lastRound) + 1 : lastRound,
            isGamePlaying: true,
            USERNUM,
            USERID,
            currentRound: -1,
            currentRoundToRender: -1,
        };

        setGameState(newGameState);
        replayGame(api, newGameState, 1, replayCallbacks);
    };

    const getCurrentWinnings = () => {
        if (numberRounds.length) {
            return (
                numberRounds
                    .filter((item, index) => index < gameState.currentRound)
                    .map((item) => parseInt(item.prize))
                    .reduce((prize, accumulated) => prize + accumulated, 0) /
                100
            );
        }

        return 0;
    };

    const getCurrentAnimatedNumber = () => {
        const currentRound = numberRounds[gameState.currentRound];

        if (gameState.isFetchingRounds) {
            return -1;
        }

        if (
            numberRounds.length > 0 &&
            animatedNumberIndex >= 0 &&
            currentRound
        ) {
            return currentRound.numbers[animatedNumberIndex];
        }

        return -1;
    };

    const getRoundsData = () => {
        if (gameState.isFetchingRounds) {
            return [];
        }

        const rounds = formatNumberRounds(numberRounds).slice(
            0,
            gameState.currentRound,
        );

        return rounds;
    };

    const setNextAnimatedNumberIndex = () => {
        if (numberRounds.length) {
            const currentNumber = getCurrentAnimatedNumber();

            if (
                currentNumber !== -1 &&
                gameNumbers.indexOf(currentNumber) === -1
            ) {
                sound.play('Keno_BallHitsBoard_miss');
            } else if (currentNumber !== -1) {
                const currentRound = numberRounds[gameState.currentRound];

                if (numberRounds.length && currentRound) {
                    let totalHitsSoFar = 0;
                    const currentNumberIndexFromHits =
                        currentRound.hits.indexOf(currentNumber);

                    currentRound.hits.forEach((hit, hitIndex) => {
                        if (hitIndex <= currentNumberIndexFromHits) {
                            totalHitsSoFar++;
                        }
                    });

                    sound.play(`Keno_BallHitsPick_${totalHitsSoFar}`);
                }
            }

            if (!gameState.isFetchingRounds) {
                setAnimatedNumberIndex((currentValue) => {
                    return currentValue + 1;
                });
            }
        }
    };

    useEffect(() => {
        initializeGame();

        // Start background music
        new Howl({
            src: [BackgroundSound],
            autoplay: true,
            loop: true,
            volume: 0.1,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (gameState.ACCOUNTVALUE !== 0) {
            setIsLoading(gameState.isFetchingRounds);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState.isFetchingRounds]);

    useEffect(() => {
        if (gameState.soundOn) {
            Howler.volume(0.1);
        } else {
            Howler.volume(0);
        }
    }, [gameState.soundOn]);
    useEffect(() => {
        if (
            animatedNumberIndex > -1 &&
            numberRounds[gameState.currentRound] &&
            animatedNumberIndex <
                numberRounds[gameState.currentRound].numbers.length
        ) {
            setTimeout(() => {
                setNextAnimatedNumberIndex();
            }, SpeedTable[speed].HOPPER_SPEED);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animatedNumberIndex]);

    useEffect(() => {
        setNextAnimatedNumberIndex();

        const storedCurrentRound = localStorage.getItem(roundStorageKey);

        if (
            gameState.currentRound >= 0 &&
            (storedCurrentRound === null ||
                storedCurrentRound < gameState.currentRound)
        ) {
            localStorage.setItem(roundStorageKey, gameState.currentRound);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState.currentRound]);

    useEffect(() => {
        const {
            numberRounds: rounds,
            isFetchingRounds,
            ignoreRound,
            currentRound,
        } = gameState;
        const roundChangedTimeout = calculateAnimationDelay(speed);

        if (rounds.length && currentRound < rounds.length) {
            if (currentRound === -1 || isFetchingRounds || ignoreRound) {
                handleRoundChanged(currentRound + 1);
            } else {
                setTimeout(() => {
                    handleRoundChanged(currentRound + 1);
                }, roundChangedTimeout);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState.numberRounds, gameState.currentRound]);

    return (
        <>
            {isLoading && <AppPreLoader isDoneLoading={!isLoading} />}
            {!isLoading && (
                <div className="game">
                    {context.error.message ? (
                        <ErrorModal
                            roundStorageKey={roundStorageKey}
                            showClose={context.error.canClose}
                            onClose={handleCloseErrorModal}
                            message={context.error.message}
                        />
                    ) : null}
                    <AppHeader
                        soundOn={gameState.soundOn}
                        toggleSound={(soundOn) =>
                            setGameState((state) => ({ ...state, soundOn }))
                        }
                        gameId={gameState.GAMESESSION}
                        roundsData={formatNumberRounds(numberRounds).slice(
                            0,
                            gameState.currentRound + 1,
                        )}
                        bank={gameState.ACCOUNTVALUE / 100}
                        bet={currentBet * gameState.DRAWS}
                        win={getCurrentWinnings()}
                    />
                    <img src={KenoLogo} alt="KenoLogo" id="keno-logo" />
                    <BoardNumbers
                        isFetchingRounds={gameState.isFetchingRounds}
                        animatedNumberIndex={animatedNumberIndex}
                        currentRound={gameState.currentRound}
                        rounds={numberRounds}
                        selectedNumbers={gameNumbers}
                        onSelectNumber={handleNumberSelect}
                    />
                    <WinningsStatus
                        payTableData={
                            gameState.isFetchingRounds
                                ? []
                                : calculatePayTable(gameNumbers, gameState)
                        }
                        roundsData={getRoundsData()}
                    />
                    <BallHopper number={getCurrentAnimatedNumber()} />
                    <GameActions
                        isGamePlaying={gameState.isGamePlaying}
                        setBet={handleSetBet}
                        bet={bet}
                        betOptions={gameState.DENOMINATIONS}
                        handleClear={handleClear}
                        setSpeed={handleSetSpeed}
                        speed={speed}
                        handleRandomPick={handleRandomPick}
                        quickPick={quickPick}
                        playGame={playGame}
                    />
                </div>
            )}
        </>
    );
};

export default Game;
