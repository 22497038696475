export function parseResponse(response) {
    let result = {};

    const parts = response.split('&');

    parts.forEach(part => {
        const values = part.split('=');

        if(values.length) {
        result[values[0]] = values[1];
        }
    });

    return result;
}

export const getAcValue = (data) => {
    const keys = Object.keys(data);
    let value = null;

    keys.forEach(key => {
        if(/^ac\d/i.test(key)) {
            value = data[key];
        }
    });

    return value;
}