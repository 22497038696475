import React from "react";
import { Button } from "@material-ui/core";
import "assets/styles/bet-amount-input.scss";
import { formatMoney, parseBetOptions } from "utils/numbers";

const BetAmountInput = ({ value, onBetChanged, betOptions, isGamePlaying }) => {
  const bets = parseBetOptions(betOptions);

  const handleBetChange = (mode) => {
    const currentBetIndex = bets.indexOf(value);

    if (mode === "inc") {
      const index =
        currentBetIndex < bets.length - 1
          ? currentBetIndex + 1
          : bets.length - 1;

      onBetChanged(bets[index]);
    } else {
      const index = currentBetIndex > 0 ? currentBetIndex - 1 : 0;

      onBetChanged(bets[index]);
    }
  };

  return (
    <div className="bet-amount-input">
      <Button
        disabled={isGamePlaying}
        className="minus"
        onClick={() => handleBetChange("dec")}
      >
        -
      </Button>
      <div className="input-container">
        <div className="border" />
        <div className="input">{formatMoney(value)}</div>
        <div className="border" />
      </div>
      <Button
        disabled={isGamePlaying}
        className="plus"
        onClick={() => handleBetChange("inc")}
      >
        +
      </Button>
    </div>
  );
};

BetAmountInput.defaultProps = {
  value: 5,
  betOptions: "5,25,50,",
  onBetChanged: () => {},
};

export default BetAmountInput;
