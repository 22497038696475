import { SET_USER } from 'actions/types/user';

const initialState = {
  name: 'USER 1',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        ...action.user,
      };

    default:
      return state;
  }
}
