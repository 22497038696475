import React, { useState } from "react";
import queryString from "query-string";
import ButtonWrapper from "components/ButtonWrapper";
import Button from "@material-ui/core/Button";
import ArrowLeftIcon from "assets/images/arrow-left.png";
import Switch from "components/Switch";
import "assets/styles/game-menu.scss";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const GameMenu = ({ gameId, soundOn, toggleSound }) => {
  const location = useLocation();
  const { t } = useTranslation();

  const urlParams = queryString.parse(location.search);
  const payoutsLink = urlParams.PAYOUTURL ? urlParams.PAYOUTURL : false;
  const helpLink = urlParams.HELPURL ? urlParams.HELPURL : false;
  const [showMenu, toggleMenu] = useState(false);

  const onSoundChange = (val) => {
    toggleSound(val);
  };

  const handleButtonLink = (link) => () => {
    window.open(link, "_blank");
  };

  const renderMenu = () => {
    if (!showMenu) {
      return null;
    }

    return (
      <div className="menu-content-wrapper">
        <div className="border" />
        {/* <ButtonWrapper>
        <img onClick={() => toggleMenu(false)} src={ReplyImage} className="reply" />
      </ButtonWrapper> */}
        <div className="title">{t("common.options")}</div>
        <div className="contents">
          <div className="sound-toggle-container">
            {t("common.sound")}
            <ButtonWrapper>
              <Switch value={soundOn} onChange={onSoundChange} />
            </ButtonWrapper>
          </div>
          <div className="buttons">
            <ButtonWrapper>
              {payoutsLink ? (
                <Button
                  color="secondary"
                  variant="contained"
                  className="purple-button"
                  onClick={handleButtonLink(payoutsLink)}
                >
                  {t("common.payout")}
                </Button>
              ) : null}
            </ButtonWrapper>
            <ButtonWrapper>
              {helpLink ? (
                <Button
                  color="secondary"
                  variant="contained"
                  className="purple-button"
                  onClick={handleButtonLink(helpLink)}
                >
                  {t("common.help")}
                </Button>
              ) : null}
            </ButtonWrapper>
            <ButtonWrapper>
              <Button
                color="secondary"
                variant="contained"
                className="purple-button"
                onClick={() => toggleMenu(false)}
              >
                {t("common.close")}
              </Button>
            </ButtonWrapper>
          </div>
          <div className="game-id">
            {t("common.gameId")}: {gameId}
          </div>
        </div>
        <div className="border" />
      </div>
    );
  };

  const renderOverlay = () => {
    if (!showMenu) {
      return null;
    }

    return <div className="overlay" />;
  };

  return (
    <div className="game-menu-wrapper">
      {renderMenu()}
      {renderOverlay()}
      <ButtonWrapper>
        <Button
          onClick={() => toggleMenu(!showMenu)}
          className="menu"
          aria-label="delete"
          size="small"
        >
          <img src={ArrowLeftIcon} alt="ArrowLeftIcon" />
        </Button>
      </ButtonWrapper>
    </div>
  );
};

GameMenu.defaultProps = {
  gameId: "",
};

export default GameMenu;
