const sprite = {
  "Keno_BallHitsBoard_miss": [
    0,
    0.596984126984127,
  ],
  "Keno_BallHitsPick_1": [
    2,
    3.0022902494331065,
  ],
  "Keno_BallHitsPick_2": [
    5,
    6.0022902494331065,
  ],
  "Keno_BallHitsPick_3": [
    8,
    9.002290249433106,
  ],
  "Keno_BallHitsPick_4": [
    11,
    12.002290249433106,
  ],
  "Keno_BallHitsPick_5": [
    14,
    15.002290249433106,
  ],
  "Keno_BallHitsPick_10": [
    17,
    19.661950113378683,
  ],
  "Keno_BallHitsPick_6": [
    21,
    22.991904761904763,
  ],
  "Keno_BallHitsPick_7": [
    24,
    26.013197278911566,
  ],
  "Keno_BallHitsPick_8": [
    28,
    29.9924716553288,
  ],
  "Keno_BallHitsPick_9": [
    31,
    32.98734693877551,
  ],
  "Keno_Option_Normal_button_select": [
    34,
    34.052244897959184,
  ],
  "Keno_Play_1_button": [
    36,
    37.491360544217684,
  ],
  "Keno_Play_3_button": [
    39,
    40.651768707482994,
  ],
  "Keno_Play_5_button": [
    42,
    43.524467120181406,
  ],
  "Keno_Win_Celebration_Small": [
    45,
    46.51718820861678,
  ],
  "Keno_Win_Celebration_big": [
    48,
    50.517709750566894,
  ],
  "Keno_bet_minus": [
    52,
    53.332244897959185,
  ],
  "Keno_bet_plus": [
    55,
    56.332244897959185,
  ],
  "Keno_number_select": [
    58,
    59.071020408163264,
  ],
  "Keno_number_unselect": [
    61,
    61.88816326530612,
  ],
  "Keno_quickpick_button_select": [
    63,
    64.87498866213151,
  ]
}

export default sprite