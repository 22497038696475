import { SET_ERROR } from './actions';

export const InitialValue = {
  error: null,
  canClose: false
};

const reducer = (state = InitialValue, action) => {
  switch (action.type) {
    case SET_ERROR:
      return {
        message: action.data.error,
        canClose: action.data.canClose
      };
    default:
      return InitialValue;
  }
}

export default reducer;
