import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

const AppRoute = ({ component: Component, ...rest }) => {
  const getElement = ({ ...props }) => (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <Component {...props} />
    </Box>
  );

  return <Route {...rest} exact render={getElement} />;
};

AppRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AppRoute;
