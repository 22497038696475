import React from "react";
import "assets/styles/board-numbers.scss";
import { numberList } from "utils/numbers";

const BoardNumbers = (props) => {
  const {
    animatedNumberIndex,
    currentRound,
    onSelectNumber,
    rounds,
    selectedNumbers,
    isFetchingRounds,
  } = props;
  const numbers = numberList(80);
  let lastRound = currentRound;

  if (currentRound >= rounds.length) {
    lastRound = rounds.length - 1;
  }

  const getNumberContainerClass = (number) => {
    let value = "";

    if (isFetchingRounds) {
      return value;
    }

    const roundNumberIndex =
      rounds && rounds[lastRound]
        ? rounds[lastRound].numbers.indexOf(number)
        : -1;
    const selectedNumberIndex = selectedNumbers.indexOf(number);

    if (selectedNumbers.indexOf(number) !== -1) {
      value = "selected";
    }

    if (
      currentRound >= 0 &&
      roundNumberIndex !== -1 &&
      (roundNumberIndex < animatedNumberIndex || currentRound >= rounds.length)
    ) {
      value = "picked";
    }

    if (value === "picked" && selectedNumberIndex !== -1) {
      value = "hit";
    }

    return value;
  };

  return (
    <div className="perfect-square">
      <div className="board-numbers-layer-1">
        <div className="board-numbers-layer-2">
          <div className="board-numbers">
            {numbers.map((number) => (
              <div
                id={`ball-${number}`}
                key={number}
                onClick={() => onSelectNumber(number)}
                className={`number-container ${getNumberContainerClass(
                  number
                )}`}
              >
                <div className="ball-content">{number}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

BoardNumbers.defaultProps = {
  currentRound: 0,
  isFetchingRounds: false,
  selectedNumbers: [],
  rounds: [],
  onSelectNumber: () => {},
};

export default BoardNumbers;
