import React, { useEffect, useState } from "react";
import HopperImage from "assets/images/hopper.png";
import "assets/styles/ball-hopper.scss";
import Ball from "./Ball";

const BallHopper = ({ number }) => {
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
  });
  const [elementClass, setClass] = useState("");

  const repositionBall = () => {
    setTimeout(() => {
      const position = document
        .querySelector(`#ball-${number} .ball-content`)
        .getBoundingClientRect();
      const wrapper = document
        .querySelector(".ball-hopper-wrapper")
        .getBoundingClientRect();
      const scale = window.game_scale_value ? window.game_scale_value : 1;
      const top = (position.top - wrapper.top) / scale;
      const left = (position.left - wrapper.left) / scale;

      setClass("grow");
      setPosition({
        top: top,
        left: left,
        position: "absolute",
      });
    }, 100);
  };

  const setInitialPositions = () => {
    setClass("");
    setPosition({
      top: 0,
      left: 46,
      position: "absolute",
    });
    repositionBall();
  };

  useEffect(() => {
    if (number > 0) {
      setInitialPositions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [number]);

  useEffect(() => {
    if (number > 0) {
      setInitialPositions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="hopper">
      {number > 0 ? (
        <Ball number={number} elementClass={elementClass} position={position} />
      ) : null}
      <div className="ball-hopper-wrapper">
        <img src={HopperImage} alt="HopperImage" id="ball-hopper" />
      </div>
    </div>
  );
};

BallHopper.defaultProps = {
  number: 0,
};

export default BallHopper;
