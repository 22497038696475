import React from 'react';
import 'assets/styles/simple-table.scss';
import { fillTableData } from 'utils/array';

const SimpleTable = (props) => {
    const {
        title,
        heading1,
        heading2,
        separator,
        data,
    } = props;
    const separatorClass = separator ? 'separator' : '';

    return <div className="table-container">
        <div className="header-container">
            <div className="title">{title}</div>
            <div className="border" />
            <div className="headers">
                <div className="heading">{heading1}</div>
                <div className="heading">{heading2}</div>
            </div>
        </div>
        <div className="data">
            {
                fillTableData(data).map((item, index) => (
                    <div className="row" key={`item-${index}`}>
                        <div className={`col ${separatorClass}`}>{item[0]}</div>
                        <div className="col">{item[1]}</div>
                    </div>
                ))
            }
        </div>
    </div>
}

SimpleTable.defaultProps = {
    title: '',
    heading1: '',
    heading2: '',
    data: [],
}

export default SimpleTable;
