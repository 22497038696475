import React, { useReducer } from 'react'
import Context from './index'
import { errorReducer, setError } from './error'

const GlobalState = (props) => {
  const { children } = props
  const [error, setErrorDispatch] = useReducer(errorReducer, {})

  return (
    <Context.Provider
      value={{
        error,
        setError: setError(setErrorDispatch)
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default GlobalState
