import React from "react";
import "assets/styles/switch.scss";
import { useTranslation } from "react-i18next";

const Switch = ({ value, onChange }) => {
  const { t } = useTranslation();
  const getWrapperClass = () => {
    let result = "switch";

    if (!value) {
      result = `${result} reverse`;
    }

    return result;
  };

  return (
    <div onClick={() => onChange(!value)} className={getWrapperClass()}>
      <div className="text">{value ? t("common.on") : t("common.off")}</div>
      <div className="dot" />
    </div>
  );
};

export default Switch;
