import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

export const getTranslation = (lng) =>
  import(`../locales/${lng}.json`)
    .then((result) => result.default)
    .catch(() => ({}));

export const getComposedTranslation = async (lng) => {
  const $lng = lng || "";
  const alt = $lng.split("-").shift();
  const translations = await Promise.all(
    Array.from(new Set(["en", $lng, alt]))
      .filter((v) => v)
      .map(getTranslation)
  );

  return translations.reduce(
    (result, translation) => ({
      ...result,
      ...translation,
    }),
    {}
  );
};

export const createData = (data, status) => ({
  data: JSON.stringify(data),
  status,
});

export const backendRequest = (_options, lng, _payload, callback) => {
  try {
    getComposedTranslation(lng).then((data) => {
      callback(null, createData(data, 200));
    });
  } catch (error) {
    callback(error, createData(error, 500));
  }
};

i18n
  .use(backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",
    detection: {
      lookupQuerystring: "LANGUAGE", // Note: case-sensitive (needs to be set to LANGUAGE)
    },
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    backend: {
      loadPath: "{{lng}}",
      request: backendRequest,
    },
  });

export default i18n;
