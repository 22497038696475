import React from "react";
import Modal from "@material-ui/core/Modal";
import "assets/styles/error-modal.scss";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const reloadErrors = ["no tickets purchased"];

const ErrorModal = (prop) => {
  const { t } = useTranslation();
  const { showClose, message, onClose, roundStorageKey } = prop;
  let actualMessage = message;
  let type = showClose ? "close" : "";

  if (message.trim() === "error.not.authenticated") {
    actualMessage = t("error.unathenticated");
    type = "";
  }

  const messageCompare = message.trim().toLowerCase();

  if (reloadErrors.some((error) => messageCompare.indexOf(error) !== -1)) {
    type = "reload";
    actualMessage = t("error.insufficientFunds");
  }

  const onReload = () => {
    localStorage.setItem(roundStorageKey, "6");

    setTimeout(() => {
      window.location.reload();
    }, 100);
  };

  const renderAction = () => {
    if (type === "close") {
      return (
        <Button onClick={onClose} variant="contained" color="secondary">
          {t("common.close")}
        </Button>
      );
    }

    if (type === "reload") {
      return (
        <Button onClick={onReload} variant="contained" color="secondary">
          {t("common.reload")}
        </Button>
      );
    }

    return <div className="cannot-close-message">{t("common.closeGame")}</div>;
  };

  return (
    <Modal className="error-modal" open={true}>
      <div className="content">
        <div className="message">{actualMessage}</div>
        {renderAction()}
      </div>
    </Modal>
  );
};

ErrorModal.defaultProps = {
  showClose: false,
};

export default ErrorModal;
