import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    typography: {
        fontFamily: '"Arial", sans-serif',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        body1: {
            fontSize: 14,
        },
        h6: {
            fontSize: 18,
            fontWeight: 400,
            textTransform: 'none',
        },
        subtitle1: {
            fontSize: 12,
        },
    },
    palette: {
        primary: {
            main: 'rgba(255, 255, 255, 1)',
        },
        secondary: {
            main: 'rgba(255,152,2,1)',
        },
    },
});

export default theme;
